import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const searchMenusAndPlaceQuery = gql`
  query searchMenusAndPlaceQuery($placeId: String, $userId: String) {
    search_menus(
      input: {
        filter: {
          menu_filter: { status: "ACTIVE" }
          place_filter: { place_id: [$placeId] }
        }
        user_id: $userId
      }
    ) {
      menu_listing {
        menu_hour {
          day
          is_active
          option {
            end
            end_in_sec
            start
            start_in_sec
            type
          }
        }
        menu_id
        name
        service_type
        service_type_setting {
          service_type_setting_id
          allow_pickup_options
          delivery_estimate
          delivery_fee
          display_table_number
          display_attendee_options
          is_custom_service
          maximum_no_of_attendees
          maximum_order_amount
          minimum_no_of_attendees
          minimum_order_amount
          payment_method
          pickup_instructions
          pickup_method
          platform
          preparation_estimate
          schedule_order
          schedule_order_duration
          schedule_order_when_closed
          service_type
        }
      }
    }
    search_places(
      input: {
        user_id: $userId
        filter: { place_filter: { place_id: [$placeId] } }
      }
    ) {
      place_listing {
        address_line_1
        address_line_2
        slug
        state
        place_id
        name
        longitude
        latitude
        city
        post_code
        status
      }
    }
    search_service_type_settings(
      input: {
        filter: { place_filter: { place_id: [$placeId] } }
        user_id: $userId
      }
    ) {
      service_type_setting_listing {
        service_type_setting_id
        service_type
      }
    }
  }
`;

export const fetchMenus = async ({ placeId, userId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchMenusAndPlaceQuery,
      variables: {
        placeId,
        userId,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_menus &&
      Array.isArray(data.data.search_menus?.menu_listing) &&
      data?.data?.search_places &&
      Array.isArray(data.data.search_places?.place_listing) &&
      data?.data?.search_service_type_settings &&
      Array.isArray(
        data.data.search_service_type_settings?.service_type_setting_listing,
      )
    ) {
      return {
        menus: data.data.search_menus.menu_listing.filter(ele => ele.menu_hour),
        place: data.data.search_places.place_listing[0],
        serviceTypes:
          data.data.search_service_type_settings.service_type_setting_listing,
      };
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const fetchTableBookingSettingQuery = gql`
  query searchTableSetting($input: SearchInput) {
    search_table_setting(input: $input) {
      table_setting_listing {
        allow_booking_cancel
        booking_cut_off_time
        allow_booking_change
        booking_hours {
          day
          is_active
          option {
            end
            end_in_sec
            start
            start_in_sec
            type
          }
        }
        booking_range_min
        booking_message
        contact {
          display
          display_order
          is_primary
          type
          value
        }
        table_details {
          table_name
          table_capacity
          table_number
        }
        display_booking_message
        image_url
        max_booking_per_slot
        max_guests_per_booking
        max_guests_per_slot
        min_guests_per_booking
        minimum_lead_time_min
        place_id
        publish_booking
        schedule_order_when_closed
        service_type
        service_type_setting_id
        status
        advance_booking_days
        auto_cancel {
          time_in_mins
          is_auto_cancel
        }
        auto_confirm
        allow_user_pre_order
        menu_id
        tag
      }
    }
  }
`;

export const fetchTableSetting = async ({ placeId }) => {
  try {
    const data = await client.clientPublic.query({
      client: client.clientPublic,
      query: fetchTableBookingSettingQuery,
      variables: {
        input: {
          filter: {
            service_type_setting_filter: {
              place_id: placeId,
              service_type: 'Table booking',
            },
          },
        },
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_setting &&
      Array.isArray(data.data.search_table_setting?.table_setting_listing) &&
      data.data.search_table_setting?.table_setting_listing.length !== 0
    ) {
      return data.data.search_table_setting.table_setting_listing[0];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const createTableSettingMutation = gql`
  mutation createTableSettingMutation($input: TableSettingInput) {
    create_table_setting(input: $input) {
      error {
        code
        description
        object {
          id
          name
          type
        }
      }
    }
  }
`;

export const createTableSetting = async ({ input }) => {
  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createTableSettingMutation,
      variables: {
        input,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.create_table_setting?.error) &&
      res.data.create_table_setting.error[0]
    ) {
      return res.data.create_table_setting.error[0]?.description;
    }
    return true;
  } catch (error) {
    return null;
  }
};

const updateTableSettingMutation = gql`
  mutation createTableSettingMutation($input: TableSettingInput) {
    update_table_setting(input: $input) {
      error {
        code
        description
        object {
          id
          name
          type
        }
      }
    }
  }
`;

export const updateTableSetting = async ({ input }) => {
  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateTableSettingMutation,
      variables: {
        input,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.update_table_setting?.error) &&
      res.data.update_table_setting.error[0]
    ) {
      return res.data.update_table_setting.error[0]?.description;
    }
    return true;
  } catch (error) {
    return null;
  }
};

export default {};
