import React, { useState, useEffect, useCallback } from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import { Tab } from 'rbx';
import uuidv4 from 'uuid/v4';
import { isNull, forEach, omit } from 'lodash';
import * as yup from 'yup';
import PlacePageHeader from '../../../components/PlacePageHeader';
import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import Information from './Information';
import Details from './Details';
import Modifiers from './Modifiers';
import AddEditModifierModal from './AddEditModifierModal';
import MenuAndSections from './MenuAndSections';

import {
  removeIdFromVariantsInput,
  addIdInVariantsInput,
  removeTypeName,
  getAllModifierGroups,
  getAllItemLink,
} from './helpers';

import OtherSettings from './OtherSettings';

const Container = styled.div`
  padding: 1rem;
`;

const t = [
  {
    key: 'item-details',
    name: 'Item Details',
    index: 2,
  },
  {
    key: 'item-modifiers',
    name: 'Item Modifiers',
    index: 3,
  },
  {
    key: 'other-settings',
    name: 'Other Settings',
    index: 4,
  },
];

const Form = props => {
  const [isActiveAddEditModal, setIsActiveAddEditModal] = useState(false);
  // const [isActiveTab, setIsActiveTab] = useState('information');
  const [searchGroupsLoading, setSearchGroupsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const {
    values,
    loading,
    dirty,
    handleSubmit,
    isCreatePage,
    setFieldValue,
    item,
    setSaveAndAddMore = () => {},
    saveAndAddMore = false,
  } = props;

  const tabs = t.filter(ele => {
    if (isCreatePage && ele.name === 'Other Settings') {
      return false;
    }
    return true;
  });

  const fetchLinkData = useCallback(async () => {
    try {
      const list = await getAllItemLink({ menu_item_id: values.menu_item_id });
      setFieldValue('updateLinks', list);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchGroupsData = useCallback(async () => {
    setSearchGroupsLoading(true);
    try {
      const groups = await getAllModifierGroups({
        modifier_group_id: props.values.modifier_group_id,
      });

      const sortGroups = () => {
        const temp = [];
        forEach(props.values.modifier_group_id, edge => {
          const data = groups.filter(
            item2 => edge && edge === item2.modifier_group_id,
          );
          if (edge && data.length !== 0) {
            temp.push(data[0]);
          }
        });
        return temp;
      };

      props.setFieldValue('modifierGroups', sortGroups());
      setSearchGroupsLoading(false);
    } catch (error) {
      console.error(error);
      setSearchGroupsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isCreatePage) {
      fetchLinkData();
      if (props.values.modifier_group_id.length !== 0) {
        fetchGroupsData();
      }
    }
  }, []);

  const prev = () =>
    activeTab === 0 ? setActiveTab(3) : setActiveTab(activeTab - 1);
  const next = () =>
    activeTab === 3 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  return (
    <>
      <Header
        loading={loading}
        dirty={dirty}
        prev={prev}
        next={next}
        itemName={values.name}
        activeTab={activeTab}
        handleSubmit={() => {
          handleSubmit();
          setActiveTab(0);
        }}
        isEdit={!!item}
        setSaveAndAddMore={setSaveAndAddMore}
        saveAndAddMore={saveAndAddMore}
      >
        <PlacePageHeader
          platform={values?.platform}
          place={props.place}
          placeId={values.place_id}
        />
      </Header>

      <Box>
        <Tabs className="tabs is-medium">
          <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
            Item Information
          </Tab>
          {(values.selling_type === 'ITEM' ||
            values.selling_type === 'DEAL') && (
            <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
              Menu and Categories
            </Tab>
          )}
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.index}
              key={tab.index}
              onClick={() => setActiveTab(tab.index)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        <Container>
          {activeTab === 0 && (
            <Information
              isLinkExist={false}
              next={() => setActiveTab('item-information')}
              {...props}
            />
          )}
          {activeTab === 1 && values.selling_type === 'ITEM' && (
            <MenuAndSections
              loading={false}
              itemVariant={values.variant}
              itemId={values.menu_item_id}
              itemRootPrice={values.price}
              placeId={values.place_id}
              {...props}
            />
          )}
          {activeTab === 2 && (
            <Details
              next={() => setActiveTab('menu-and-category')}
              {...props}
            />
          )}
          {activeTab === 3 && (
            <Modifiers
              {...props}
              next={() => setActiveTab('item-details')}
              setIsActiveAddEditModal={setIsActiveAddEditModal}
              searchGroupsLoading={searchGroupsLoading}
            />
          )}
          {activeTab === 4 && <OtherSettings values={values} />}
        </Container>
      </Box>
      <hr />
      <Footer
        loading={loading}
        dirty={dirty}
        prev={prev}
        next={next}
        activeTab={activeTab}
        handleSubmit={() => {
          handleSubmit();
          setActiveTab(0);
        }}
        isEdit={!!item}
        setSaveAndAddMore={setSaveAndAddMore}
        saveAndAddMore={saveAndAddMore}
      />

      <AddEditModifierModal
        {...props}
        isActiveAddEditModal={isActiveAddEditModal}
        setIsActiveAddEditModal={setIsActiveAddEditModal}
      />
    </>
  );
};

const MenuItemForm = withFormik({
  mapPropsToValues: ({ place, userId, item }) => ({
    minimum_qty_per_order:
      item && !isNull(item.minimum_qty_per_order)
        ? item.minimum_qty_per_order
        : null,
    minimum_serving_size:
      item && !isNull(item.minimum_serving_size)
        ? item.minimum_serving_size
        : null,
    maximum_serving_size:
      item && !isNull(item.maximum_serving_size)
        ? item.maximum_serving_size
        : null,

    user_id: userId,
    platform: item && !isNull(item.platform) ? item.platform : '',
    channel_link_id:
      item && !isNull(item.channel_link_id) ? item.channel_link_id : '',
    deliverect_menu_item_id:
      item && !isNull(item.deliverect_menu_item_id)
        ? item.deliverect_menu_item_id
        : '',
    deliverect_plu_id:
      item && !isNull(item.deliverect_plu_id) ? item.deliverect_plu_id : '',
    menu_item_id:
      item && !isNull(item.menu_item_id) ? item.menu_item_id : uuidv4(),
    place_id: item && !isNull(item.place_id) ? item.place_id : place.place_id,
    modifier_group_id:
      item && !isNull(item.modifier_group_id) ? item.modifier_group_id : [],
    name: item && !isNull(item.name) ? item.name : '',
    internal_name:
      item && !isNull(item.internal_name) ? item.internal_name : '',
    slug: item && !isNull(item.slug) ? item.slug : '',
    description: item && !isNull(item.description) ? item.description : '',
    status: item && !isNull(item.status) ? item.status : 'ACTIVE',
    approval_status:
      item && !isNull(item.approval_status) ? item.approval_status : 'APPROVED',
    source_type: item && !isNull(item.source_type) ? item.source_type : [],
    tag: item && !isNull(item.tag) ? item.tag : [],
    variant:
      item && !isNull(item.variant) ? addIdInVariantsInput(item.variant) : [],
    popular: item && !isNull(item.popular) ? item.popular : false,
    allow_item_review:
      item && !isNull(item.allow_item_review) ? item.allow_item_review : false,
    availability_status:
      item && !isNull(item.availability_status)
        ? item.availability_status
        : 'AVAILABLE',
    price:
      item && !isNull(item.price) ? parseFloat(item.price).toFixed(2) : null,
    dummy_price:
      item && !isNull(item.price) ? parseFloat(item.price).toFixed(2) : null,
    tax: item && !isNull(item.tax) ? item.tax : 'GST',
    cuisine_style:
      item && !isNull(item.cuisine_style)
        ? removeTypeName(item.cuisine_style)
        : [],
    item_type:
      item && !isNull(item.item_type) ? removeTypeName(item.item_type) : [],
    dietary_information:
      item && !isNull(item.dietary_information)
        ? removeTypeName(item.dietary_information)
        : [],
    item_contains_alcohol:
      item && !isNull(item.item_contains_alcohol)
        ? item.item_contains_alcohol
        : false,
    calories: item && !isNull(item.calories) ? item.calories : null,
    kilojoules: item && !isNull(item.kilojoules) ? item.kilojoules : null,
    label: null,
    spice_levels: item && !isNull(item.spice_levels) ? item.spice_levels : null,
    item_stored: item && !isNull(item.item_stored) ? item.item_stored : '',
    sell_item_on_its_own:
      item && !isNull(item.sell_item_on_its_own)
        ? item.sell_item_on_its_own
        : true,
    popular_addon:
      item && !isNull(item.popular_addon) ? item.popular_addon : false,
    allow_special_instructions:
      item && !isNull(item.allow_special_instructions)
        ? item.allow_special_instructions
        : false,
    image_url: item && !isNull(item.image_url) ? item.image_url : null,
    modifierGroups: [],
    createLinks: [],
    updateLinks: [],
    deleteLinks: [],
    selling_type:
      item && !isNull(item.selling_type) ? item.selling_type : 'ITEM',
  }),

  validate: values => {
    const errors = {};
    if (values.variant.length === 0 && !values.price) {
      errors.price = 'Price is required!';
    }
    return errors;
  },
  // enableReinitialize: true,

  validationSchema: yup.object().shape({
    minimum_qty_per_order: yup
      .number()
      .nullable()
      .notRequired()
      .transform((value, originalValue) => (!originalValue ? 1 : value))
      .min(1, 'Minimum value of minimum quantity is 1!')
      .max(9999, 'Maximum value of minimum quantity is 9999!'),
    minimum_serving_size: yup
      .number()
      .nullable()
      .notRequired()
      .transform((value, originalValue) => (!originalValue ? 1 : value))
      .min(1, 'Minimum value of minimum serving size is 1!')
      .max(9999, 'Maximum value of minimum serving size is 9999!'),
    maximum_serving_size: yup
      .number()
      .nullable()
      .notRequired()
      .transform((value, originalValue) => (!originalValue ? 1 : value))
      .min(1, 'Minimum value of maximum serving size is 1!')
      .max(9999, 'Maximum value of maximum serving size is 9999!'),
    name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Please enter not greater than 50 characters')
      .required('Item Name is required!'),
    internal_name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Internal Name cannot be greater than 50 characters')
      .required('Internal Name is required!'),
    tax: yup.string().required('Tax is required!'),
    description: yup
      .string()
      .min(5, 'Please enter at least 5 characters')
      .max(250, 'Please enter not greater than 250 characters'),
    variant: yup.array().of(
      yup.object().shape({
        prompt: yup
          .string()
          .trim()
          .required('Prompt is required'),
        variants: yup.array().of(
          yup.object().shape({
            variant_name: yup
              .string()
              .trim()
              .required('Name is required'),
            variant_price: yup
              .number()
              .nullable('Price is required')
              .required('Price is required'),
          }),
        ),
      }),
    ),
  }),
  handleSubmit: (values, { props, resetForm, setFieldValue }) => {
    // const initialsValues = {
    //   ...props.item,
    //   cuisine_style: removeTypeName(props.item.cuisine_style),
    //   item_type: removeTypeName(props.item.item_type),
    //   dietary_information: removeTypeName(props.item.dietary_information),
    //   variant: removeIdFromVariantsInput(
    //     addIdInVariantsInput(props.item.variant),
    //   ),
    // };
    // const input = diff.observableDiff(values, initialsValues, e => {
    //   const data = diff.applyChange(values, initialsValues);
    //   console.log('event', data);
    // });
    const createLinks = values.createLinks.map(item => ({
      user_id: props.userId,
      item_link_id: item.item_link_id,
      place_id: item.place_id,
      service_type_setting_id:
        item.service_type_setting.service_type_setting_id,
      menu_id: item.menu.menu_id,
      menu_section_id: item.menu_section.menu_section_id,
      menu_item_id: item.menu_item_id,
      price: item.price ? item.price : null,
      variant:
        item.variant && item.variant.length !== 0
          ? item.variant.map(item2 => {
              const temp = omit(item2, '__typename');
              const variants = item2.variants.map(item3 =>
                omit(item3, '__typename'),
              );
              return { ...temp, variants };
            })
          : null,
    }));
    const updateLinks = values.updateLinks.map(item => ({
      user_id: props.userId,
      item_link_id: item.item_link_id,
      price: item.price,
      variant:
        item.variant && item.variant.length !== 0
          ? item.variant.map(item2 => {
              const temp = omit(item2, '__typename');
              const variants = item2.variants.map(item3 =>
                omit(item3, '__typename'),
              );
              return { ...temp, variants };
            })
          : null,
    }));
    const modifier_group_id = values.modifierGroups.map(
      item => item.modifier_group_id,
    );
    const variants = removeIdFromVariantsInput(values.variant);
    const deleteLinks = values.deleteLinks.map(edge => ({
      user_id: props.userId,
      item_link_id: edge,
    }));
    // console.log('input', input);
    props.onSubmit(
      {
        ...values,
        variant: variants,
        modifier_group_id,
        createLinks,
        updateLinks,
        deleteLinks,
      },
      () => {
        resetForm();
        setFieldValue('menu_item_id', uuidv4());
      },
    );
  },

  displayName: 'MenuTypeForm',
})(Form);

export default MenuItemForm;
