import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';

import { fetchMenus, fetchTableSetting } from './helpers';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import TableBookingSettingForm from './TableBookingSettingForm';

const LoadingText = styled.span`
  padding: 1rem;
  color: #00aeef;
`;

const TableBookingSetting = ({ match, history }) => {
  const { userId } = useStoreState(state => state.auth);

  const [menus, setMenus] = React.useState([]);
  const [activeMenu, setActiveMenu] = React.useState(null);
  const [loadingMenus, setLoadingMenus] = React.useState(true);
  const [place, setPlace] = React.useState();
  const [serviceTypes, setServiceTypes] = React.useState();
  const [tableBookingSetting, setTableBookingSetting] = React.useState(null);

  const [loadingTableBookingData, setLoadingTableBookingData] = React.useState(
    false,
  );

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoadingMenus(true);
      const res = await fetchMenus({ placeId: match.params.placeId, userId });
      if (res) {
        setMenus(res?.menus);
        setPlace(res?.place);
        setServiceTypes(res?.serviceTypes);
      }
      setLoadingMenus(false);
    };
    fetchFunc();
  }, []);

  React.useEffect(() => {
    if (menus.length !== 0) {
      setActiveMenu(menus[0]);
    }
  }, [menus]);

  React.useEffect(() => {
    const fetchFunc = async () => {
      const checkTableBooking = tb =>
        tb === 'Table booking' ||
        tb === 'Table Booking' ||
        tb === 'Table bookings';
      setLoadingTableBookingData(true);
      const tableBooking = (serviceTypes ?? []).find(ele =>
        checkTableBooking(ele.service_type),
      );
      if (tableBooking) {
        const res = await fetchTableSetting({
          placeId: match.params.placeId,
        });
        if (res) {
          setTableBookingSetting(res);
          if (res.menu_id) {
            setActiveMenu(menus.filter(ele => ele.menu_id === res.menu_id));
          }
        }
      }
      setLoadingTableBookingData(false);
    };
    if (serviceTypes) {
      fetchFunc();
    }
  }, [serviceTypes]);

  if (loadingMenus || !activeMenu) {
    return (
      <Layout>
        <Header />
        <LoadingText>loading Place & Menu details...</LoadingText>
      </Layout>
    );
  }

  if (loadingTableBookingData) {
    return (
      <Layout>
        <Header />
        <LoadingText>fetching Table Booking Settings...</LoadingText>
      </Layout>
    );
  }

  return (
    <TableBookingSettingForm
      userId={userId}
      place={place}
      menus={menus}
      activeMenu={activeMenu}
      setActiveMenu={setActiveMenu}
      history={history}
      tableBookingSetting={tableBookingSetting}
    />
  );
};

export default TableBookingSetting;
