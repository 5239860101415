import React from 'react';
import * as yup from 'yup';
import { withFormik } from 'formik';

import uuid from 'uuid';
import uuidv4 from 'uuid/v4';

import { isNull } from 'lodash';

import swal from 'sweetalert';

import { toast } from 'react-toastify';

// import { isPossiblePhoneNumber } from 'react-phone-number-input';

import {
  placeBusinessHoursHandler,
  menuHourSorter,
} from '../../../utils/helpers';

import { addIdInputHours, parseFinalInputHours } from '../Menu/helper';

import Layout from '../../../components/global/Layout';
import Header from './Header';

import { createTableSetting, updateTableSetting } from './helpers';

// import {
//   Box,
//   Tabs,
//   Select,
//   Input,
//   CheckBox,
//   TextArea,
//   // InputErrorMessage,
// } from '../../components/elements';

import TabSection from './TabSection';

import OnlineBookings from './OnlineBookings';
import BookingHours from './BookingHours';
import NotificationSettings from './NotificationSettings';
import TagsTable from './TagsTable';

import Footer from './Footer';

const Form = ({ ...props }) => {
  const [tab, setTab] = React.useState(0);

  const {
    // loading,
    // dirty,
    userId,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    activeMenu,
    menus,
    // setActiveMenu,
    place,
    history,
  } = props;

  console.log('values', values);

  const onSubmit = event => {
    event.preventDefault();
    handleSubmit();
  };

  console.log('userId', userId);

  return (
    <Layout>
      <form onSubmit={onSubmit}>
        <Header
          history={history}
          loading={isSubmitting}
          showSubmit
          place={place}
        />
        <TabSection setTab={setTab} tab={tab} />
        {activeMenu && (
          <>
            {tab === 0 && (
              <OnlineBookings
                place={place}
                menuOptions={menus.map(ele => ({
                  value: ele,
                  label: ele.name,
                }))}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                selectedMenuValue={{
                  value: values.selectedMenu,
                  label: values.selectedMenu.name,
                }}
                onChangeMenu={mnu => {
                  placeBusinessHoursHandler(addIdInputHours(mnu.menu_hour));
                }}
              />
            )}
            {tab === 1 && (
              <BookingHours
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            )}
            {tab === 3 && (
              <TagsTable values={values} setFieldValue={setFieldValue} />
            )}
            {tab === 4 && <NotificationSettings />}
          </>
        )}
        <Footer history={history} showSubmit loading={isSubmitting} />
      </form>
    </Layout>
  );
};

const TableBookingSettingForm = withFormik({
  mapPropsToValues: ({ activeMenu, tableBookingSetting }) => ({
    service_type: 'Table booking',

    service_type_setting_id:
      tableBookingSetting &&
      !isNull(tableBookingSetting.service_type_setting_id)
        ? tableBookingSetting.service_type_setting_id
        : uuidv4(),

    status:
      tableBookingSetting && !isNull(tableBookingSetting.status)
        ? tableBookingSetting.status
        : 'ACTIVE',

    image_url:
      tableBookingSetting && !isNull(tableBookingSetting.image_url)
        ? tableBookingSetting.image_url
        : '',

    publish_booking:
      tableBookingSetting && !isNull(tableBookingSetting.publish_booking)
        ? tableBookingSetting.publish_booking
        : true,

    contact_phone:
      tableBookingSetting &&
      Array.isArray(tableBookingSetting.contact) &&
      tableBookingSetting.contact.find(ele => ele.type === 'phone_primary')
        ? (
            tableBookingSetting.contact.find(
              ele => ele.type === 'phone_primary',
            ) ?? {}
          ).value
        : '',

    contact_email:
      tableBookingSetting &&
      Array.isArray(tableBookingSetting.contact) &&
      tableBookingSetting.contact.find(ele => ele.type === 'email_primary')
        ? (
            tableBookingSetting.contact.find(
              ele => ele.type === 'email_primary',
            ) ?? {}
          ).value
        : '',

    allow_booking_cancel:
      tableBookingSetting && !isNull(tableBookingSetting.allow_booking_cancel)
        ? tableBookingSetting.allow_booking_cancel
        : true,

    allow_booking_change:
      tableBookingSetting && !isNull(tableBookingSetting.allow_booking_change)
        ? tableBookingSetting.allow_booking_change
        : true,

    auto_confirm:
      tableBookingSetting && !isNull(tableBookingSetting.auto_confirm)
        ? tableBookingSetting.auto_confirm
        : true,

    is_auto_cancel:
      tableBookingSetting && !isNull(tableBookingSetting.auto_cancel)
        ? tableBookingSetting.auto_cancel.is_auto_cancel
        : true,

    time_in_mins:
      tableBookingSetting && !isNull(tableBookingSetting.auto_cancel)
        ? tableBookingSetting.auto_cancel.time_in_mins
        : 10,

    allow_user_pre_order:
      tableBookingSetting && !isNull(tableBookingSetting.allow_user_pre_order)
        ? tableBookingSetting.allow_user_pre_order
        : true,

    schedule_order_when_closed:
      tableBookingSetting &&
      !isNull(tableBookingSetting.schedule_order_when_closed)
        ? tableBookingSetting.schedule_order_when_closed
        : true,

    booking_message:
      tableBookingSetting && !isNull(tableBookingSetting.booking_message)
        ? tableBookingSetting.booking_message
        : '',

    display_booking_message:
      tableBookingSetting &&
      !isNull(tableBookingSetting.display_booking_message)
        ? tableBookingSetting.display_booking_message
        : false,

    advance_booking_days:
      tableBookingSetting && !isNull(tableBookingSetting.advance_booking_days)
        ? tableBookingSetting.advance_booking_days
        : 10,

    minimum_lead_time_min:
      tableBookingSetting && !isNull(tableBookingSetting.minimum_lead_time_min)
        ? tableBookingSetting.minimum_lead_time_min
        : 10,

    booking_range_min:
      tableBookingSetting && !isNull(tableBookingSetting.booking_range_min)
        ? tableBookingSetting.booking_range_min
        : 10,

    booking_cut_off_time:
      tableBookingSetting && !isNull(tableBookingSetting.booking_cut_off_time)
        ? tableBookingSetting.booking_cut_off_time
        : 10,

    max_booking_per_slot:
      tableBookingSetting && !isNull(tableBookingSetting.max_booking_per_slot)
        ? tableBookingSetting.max_booking_per_slot
        : 10,

    max_guests_per_booking:
      tableBookingSetting && !isNull(tableBookingSetting.max_guests_per_booking)
        ? tableBookingSetting.max_guests_per_booking
        : 10,

    max_guests_per_slot:
      tableBookingSetting && !isNull(tableBookingSetting.max_guests_per_slot)
        ? tableBookingSetting.max_guests_per_slot
        : 10,

    min_guests_per_booking:
      tableBookingSetting && !isNull(tableBookingSetting.min_guests_per_booking)
        ? tableBookingSetting.min_guests_per_booking
        : 10,

    selectedMenu: activeMenu,

    booking_hours:
      tableBookingSetting && !isNull(tableBookingSetting.booking_hours)
        ? placeBusinessHoursHandler(
            addIdInputHours(tableBookingSetting.booking_hours),
          )
        : placeBusinessHoursHandler(addIdInputHours(activeMenu.menu_hour)),

    tag:
      tableBookingSetting && !isNull(tableBookingSetting.tag)
        ? tableBookingSetting.tag.map(ele => ({ name: ele, id: uuid() }))
        : [
            { name: 'Anniversary', id: uuid() },
            { name: 'Birthday', id: uuid() },
          ],
    table_details:
      tableBookingSetting && !isNull(tableBookingSetting.table_details)
        ? tableBookingSetting.table_details.map(ele => ({
            ...ele,
            id: uuid(),
          }))
        : [
            {
              table_capacity: 20,
              table_name: 'TABLE',
              table_number: '2',
              id: uuid(),
            },
            {
              table_capacity: 30,
              table_name: 'TABLE',
              table_number: '3',
              id: uuid(),
            },
          ],
  }),
  validationSchema: yup.object().shape({
    image_url: yup
      .string()
      .url('Image Url be a valid URL')
      .required('Please add an image'),

    display_booking_message: yup.boolean().required(),

    booking_message: yup.string().when('display_booking_message', {
      is: true,
      then: yup
        .string()
        .min(10, 'Booking Message must be at least 10 characters long')
        .max(100, 'Booking Message must be at most 100 characters long')
        .test(
          'noSpaces',
          'Please enter a valid booking message',
          value => value && value.trim().length > 0,
        )
        .required('Booking Message is required'),
      otherwise: yup
        .string()
        .min(10, 'Booking Message must be at least 10 characters long')
        .max(100, 'Booking Message must be at most 100 characters long')
        .notRequired(),
    }),
    advance_booking_days: yup
      .number()
      .transform((value, originalValue) => (!originalValue ? 0 : value))
      .required('Advance Booking Days is required')
      .integer('Advance Booking Days must be a valid integer')
      .min(1, 'Advance Booking Days must be greater than 1')
      .max(100, 'Advance Booking Days must be less than 100'),
    minimum_lead_time_min: yup
      .number()
      .transform((value, originalValue) => (!originalValue ? 0 : value))
      .required('Minimum Lead Time is required')
      .integer('Minimum Lead Time must be a valid integer')
      .min(1, 'Minimum Lead Time must be greater than 1')
      .max(100, 'Minimum Lead Time must be less than 100'),
    booking_range_min: yup
      .number()
      .transform((value, originalValue) => (!originalValue ? 0 : value))
      .required('Booking Range Min is required')
      .integer('Booking Range Min must be a valid integer')
      .min(1, 'Booking Range Min must be greater than 1')
      .max(100, 'Booking Range Min must be less than 100'),
    booking_cut_off_time: yup
      .number()
      .transform((value, originalValue) => (!originalValue ? 0 : value))
      .required('Booking Cut Off Time is required')
      .integer('Booking Cut Off Time must be a valid integer')
      .min(1, 'Booking Cut Off Time must be greater than 1')
      .max(100, 'Booking Cut Off Time must be less than 100'),
    max_booking_per_slot: yup
      .number()
      .transform((value, originalValue) => (!originalValue ? 0 : value))
      .required('Maximum Booking Per Slot is required')
      .integer('Maximum Booking Per Slot must be a valid integer')
      .min(1, 'Maximum Booking Per Slot must be greater than 1')
      .max(100, 'Maximum Booking Per Slot must be less than 100'),
    max_guests_per_booking: yup
      .number()
      .transform((value, originalValue) => (!originalValue ? 0 : value))
      .required('Maximum Guests Per Booking is required')
      .integer('Maximum Guests Per Booking must be a valid integer')
      .min(1, 'Maximum Guests Per Booking must be greater than 1')
      .max(100, 'Maximum Guests Per Booking must be less than 100'),
    max_guests_per_slot: yup
      .number()
      .transform((value, originalValue) => (!originalValue ? 0 : value))
      .required('Max Guests Per Slot is required')
      .integer('Max Guests Per Slot must be a valid integer')
      .min(1, 'Max Guests Per Slot must be greater than 1')
      .max(100, 'Max Guests Per Slot must be less than 100'),
    min_guests_per_booking: yup
      .number()
      .transform((value, originalValue) => (!originalValue ? 0 : value))
      .required('Minimum Guests Per Booking is required')
      .integer('Minimum Guests Per Booking must be a valid integer')
      .min(1, 'Minimum Guests Per Booking must be greater than 1')
      .max(100, 'Minimum Guests Per Booking must be less than 100'),
    contact_email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required'),

    contact_phone: yup.string().required('Contact Phone is required'),
  }),

  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: async (values, { props, setSubmitting }) => {
    setSubmitting(true);
    const {
      contact_phone,
      contact_email,
      image_url,
      publish_booking,
      allow_booking_cancel,
      allow_booking_change,
      auto_confirm,
      allow_user_pre_order,
      schedule_order_when_closed,
      display_booking_message,
      booking_message,
      advance_booking_days,
      minimum_lead_time_min,
      booking_range_min,
      booking_cut_off_time,
      max_booking_per_slot,
      max_guests_per_booking,
      max_guests_per_slot,
      min_guests_per_booking,
      service_type_setting_id,
      service_type,
      status,
      selectedMenu,
      table_details,
      tag,
      booking_hours,
      is_auto_cancel,
      time_in_mins,
    } = values;
    const tableDetails = table_details.map(ele => {
      const { id, __typename, ...temp } = ele;
      console.log(id, __typename);
      return { ...temp };
    });
    const ttag = tag.map(ele => {
      const { id, name } = ele;
      console.log(id);
      return name;
    });

    const input = {
      image_url,
      publish_booking,
      allow_booking_cancel,
      allow_booking_change,
      auto_confirm,
      allow_user_pre_order,
      schedule_order_when_closed,
      display_booking_message,
      booking_message,
      advance_booking_days,
      minimum_lead_time_min,
      booking_range_min,
      booking_cut_off_time,
      max_booking_per_slot,
      max_guests_per_booking,
      max_guests_per_slot,
      min_guests_per_booking,
      auto_cancel: { is_auto_cancel, time_in_mins },
      service_type_setting_id,
      service_type,
      user_id: props.userId,
      status,
      menu_id: selectedMenu.menu_id,
      place_id: props.place.place_id,
      tag: ttag,
      table_details: tableDetails,
      booking_hours: menuHourSorter(parseFinalInputHours(booking_hours)),
      contact: [
        {
          display: true,
          display_order: null,
          is_primary: true,
          type: 'email_primary',
          value: contact_email,
        },
        {
          display: true,
          display_order: null,
          is_primary: true,
          type: 'phone_primary',
          value: contact_phone,
        },
      ],
    };

    if (props.tableBookingSetting) {
      console.log('input...', input);
      const res = await updateTableSetting({ input });
      if (res === true) {
        swal('Successfully updated table booking setting...').then(() => {
          setSubmitting(false);
          props.history.goBack();
        });
      } else if (res === null) {
        swal({
          title: 'Error!',
          text: 'An unexpected error occurred. Please try again.',
          type: 'error',
          confirmButtonText: 'Okay',
        }).then(() => {
          setSubmitting(false);
        });
      } else {
        toast.error(res, {
          onClose: () => {
            setSubmitting(false);
          },
        });
      }
    } else {
      console.log('input...', input);
      const res = await createTableSetting({ input });
      if (res === true) {
        swal('Successfully created table booking setting...').then(() => {
          setSubmitting(false);
          props.history.goBack();
        });
      } else if (res === null) {
        swal({
          title: 'Error!',
          text: 'An unexpected error occurred. Please try again.',
          type: 'error',
          confirmButtonText: 'Okay',
        }).then(() => {
          setSubmitting(false);
        });
      } else {
        toast.error(res, {
          onClose: () => {
            setSubmitting(false);
          },
        });
      }
    }
  },
  displayName: 'Form',
})(Form);

export default TableBookingSettingForm;
