import React from 'react';
import styled from 'styled-components';

import { Label } from 'rbx';

import {
  CheckBox,
  // InputErrorMessage,
} from '../../../components/elements';

const Container2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const PaddingDiv = styled.div`
  padding: ${props => props.verticalPadding || '1rem'}
    ${props => props.horizontalPadding || '0rem'};
`;

const NotificationSettings = () => (
  <div style={{ paddingLeft: '4px' }}>
    <p>Notification Settings</p>
    <Container2>
      <div>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '400px' }}>
              Venue Email Notification on New Booking
            </div>
            <CheckBox />
          </Label>
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '400px' }}>
              User Email Notification on Confirmation
            </div>
            <CheckBox />
          </Label>
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '400px' }}>
              User SMS Notification on Confirmation
            </div>
            <CheckBox />
          </Label>
        </PaddingDiv>
      </div>
      <div>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '400px' }}>
              Venue Email Notification on Cancel / Modify
            </div>
            <CheckBox />
          </Label>
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '400px' }}>
              User Email Notification on Cancel / Modify
            </div>
            <CheckBox />
          </Label>
        </PaddingDiv>
        <PaddingDiv>
          <Label className="is-flex">
            <div style={{ width: '400px' }}>
              Send Auto Reminder to user before booking time
            </div>
            <input
              type="number"
              id="numberInput"
              name="numberInput"
              min="0"
              max="100"
              step="1"
              value="50"
            />
          </Label>
        </PaddingDiv>
      </div>
    </Container2>
  </div>
);

export default NotificationSettings;
